function facturesController(DatatableLang, $state, $scope, WS, $sce, $window, AppSettings, $translate, $compile, Flash, $http, $uibModal) {
    'ngInject';
    const vm = this;

    vm.tabsFacture = {};
    vm.tabsFacture.bls = [];
    vm.liste_bl = [];
    vm.lignes = [];
    vm.tabsFacture.remiseEspece = 0;
    vm.tabsFacture.ttc = 0;
    vm.tabsFacture.net = 0;

    vm.bls = {};
    vm.center = "35.981789, 10.076292";
    vm.countblock = 1;
    vm.newbl = {};
    vm.newbl.totalRemise = 0;
    vm.newbl.totalTtc = 0;
    vm.newbl.totalNet = 0;
    vm.newbl.gratuites = [];
    vm.newbl.lignes = [];

    vm.class_md_prod = 'col-md-5';
    vm.keymaps = AppSettings.keymaps;
    vm.per_page = "10";
    vm.quantites = {};

    vm.listofProdGratuite = '';


    vm.keymaps = AppSettings.keymaps;
    vm.per_page = "10";
    vm.currentLang = $translate.proposedLanguage() || $translate.use();
    vm.users = {};

    vm.search = {};
    vm.search.date_debut = moment().format('YYYY-MM-DD');
    vm.search.date_fin = moment().format('YYYY-MM-DD');
    vm.search.status_facture = 'Tous';
    vm.checkedBls = {};
    /**
         * CHECK CONTROLE DE STOCK
         */
    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
                //vm.global_gestion_stock = 1;
                if (vm.global_gestion_stock == 1) {
                    vm.class_md_prod = 'col-md-3';
                } else {
                    vm.class_md_prod = 'col-md-5';
                }
                //console.log('vm.global_gestion_stock', vm.global_gestion_stock);
            }, function errorCallback(error) {
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });
    vm.searchByDate = function (facturedate) {
        if (facturedate == undefined || facturedate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (facturedate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (facturedate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            if (facturedate.commercial && facturedate.commercial != "") {
                var url = 'factures/getByDate/' + facturedate.date_debut + '/' + facturedate.date_fin + '/' + facturedate.commercial;
            } else {
                var url = 'factures/getByDate/' + facturedate.date_debut + '/' + facturedate.date_fin + '/null';
            }
            if (facturedate.cloture != '') {
                var url = url + '/' + facturedate.cloture;
            } else {
                var url = url + '/null';
            }

            if (facturedate.validation != '') {
                var url = url + '/' + facturedate.validation;
            } else {
                var url = url + '/null';
            }
            WS.getScache(url)
                .then(function (response) {

                    vm.totalremise = 0;
                    vm.totalnet = 0;
                    vm.factures = response.data;
                    response.data.forEach(function (e, index) {
                        vm.totalremise += parseFloat(Math.abs(e.remise));
                        vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                    });
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    }
    vm.getFacturescleared = function () {
        WS.get('factures/top50', vm.access)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.factures = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getFactures = function () {

        var user_code = $state.params.user_code;
        var date_debut = $state.params.date_debut;
        var date_fin = $state.params.date_fin;

        if (user_code != '' && user_code != undefined) {
            vm.bldate = {};
            vm.bldate.commercial = user_code;
            vm.bldate.date_debut = date_debut;
            vm.bldate.date_fin = date_fin;
            var url = 'factures/top50/' + user_code + '/' + date_debut + '/' + date_fin;
        } else {
            var url = 'factures/top50';
        }


        WS.get(url)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.factures = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
                $('.table-factures').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getDetailsbyCode = function (code) {
        var url = 'factures/ByCode/' + $state.params.id;
        vm.liste_bl = [];
        vm.lignes = [];
        WS.get(url)
            .then(function (response) {
                vm.details = response.data;
                response.data.entetecommercials.forEach(function (e, index) {
                    let bl = new Object();
                    bl.code = e.code;
                    bl.date = e.date;
                    bl.montant_total_ttc = e.montant_total_ttc;
                    bl.remise = e.remise;
                    bl.net_a_payer = e.net_a_payer;
                    bl.solde = e.solde;
                    vm.liste_bl.push(bl);
                    e.lignes.forEach(function (e, index) {
                        vm.lignes.push(e)
                    });

                });
                $scope.$apply();
            }).then(null, function (error) {
                console.log(error);
            });
    };




    vm.ConvNumberLetter_fr = function (Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();

        if (isNaN(parseFloat(Nombre))) return "";

        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0);
            }
            return strLetter + 'Millimes';
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = " virgule";
                break;
            case 1:
                strDev = " Dinar";
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = " Dollar";
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }
        if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";
        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = "mille ";
                break;
            default:
                StrTmp = StrTmp + " mille ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " million ";
                break;
            default:
                StrTmp = StrTmp + " millions ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " milliard ";
                break;
            default:
                StrTmp = StrTmp + " milliards ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " billion ";
                break;
            default:
                StrTmp = StrTmp + " billions ";
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept",
            "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze",
            "seize", "dix-sept", "dix-huit", "dix-neuf");
        TabDiz = Array("", "", "vingt", "trente", "quarante", "cinquante",
            "soixante", "soixante", "quatre-vingt", "quatre-vingt");
        if (Langue == 1) {
            TabDiz[7] = "septante";
            TabDiz[9] = "nonante";
        } else if (Langue == 2) {
            TabDiz[7] = "septante";
            TabDiz[8] = "huitante";
            TabDiz[9] = "nonante";
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = " et ";
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }



    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix");

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    };

    vm.waveSoft = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/wavesoft/' + bldate.date_debut + '/' + bldate.date_fin + '/commande';
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };



    vm.parseExcel = function (file) {
        var reader = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            });
            workbook.SheetNames.forEach(function (sheetName) {
                // Here is your object
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                var json_object = JSON.stringify(XL_row_object);
                var prods = JSON.parse(json_object);

                vm.addProdsToCmd(prods);
            })
        };

        reader.onerror = function (ex) {
            console.log(ex);
        };

        reader.readAsBinaryString(file);
    };

    /**
     * upload excel file
     */
    vm.UplpoadExcelFile = () => {
        if (vm.newbl.distributeur_code && vm.newbl.depot_code && vm.newbl.client_code) {
            console.log('UplpoadExcelFile')
            var files = vm.files; // FileList object
            // var xl2json = vm.ExcelToJSON();
            vm.parseExcel(files[0]);
        } else {
            Flash.create('danger', $translate.instant("Sélectionner (distributeur,Commercial,Client) avant d'importer le fichier"));
        }

    };


    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };

    vm.selectDepots = function () {
        WS.get('depots/selectDepot').then(
            function (response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.newbl.distributeur_code = vm.soussocietes[0].code;
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };




    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 
     * @param {string} html 
     * @return compiled html
     */
    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };


    vm.checkByClient = {};


    vm.recalculateAllSelectedBls = () => {

        vm.totalremise = 0;
        vm.totalnet = 0;
        vm.montant_total_ttc = 0;
        vm.total_facture = 0;
        vm.total_non_facture = 0;

        _.each(vm.clients_bls, (bls, client_code) => {

            _.each(bls, (bl, index) => {
                if (bl.checked) {
                    vm.totalremise += parseFloat(Math.abs(bl.remise));
                    vm.totalnet += parseFloat(Math.abs(bl.net_a_payer));
                    vm.montant_total_ttc += parseFloat(Math.abs(bl.montant_total_ttc));
                    vm.total_facture += parseFloat(Math.abs(bl.total_facture));
                    vm.total_non_facture += parseFloat(Math.abs(bl.total_non_facture));
                }

            });

        });
    }

    vm.recalculateTotalBYClient = (client_code) => {
        // recalculate total after check or uncheck client
        vm.total_selected_by_client[client_code] = {};
        vm.total_selected_by_client[client_code].remise = 0;
        vm.total_selected_by_client[client_code].montant_total_ttc = 0;
        vm.total_selected_by_client[client_code].net_a_payer = 0;
        vm.total_selected_by_client[client_code].total_facture = 0;
        vm.total_selected_by_client[client_code].total_non_facture = 0;
        _.each(vm.clients_bls[client_code], (bl, index) => {


            if (vm.clients_bls[client_code][index].checked) {
                vm.total_selected_by_client[client_code].remise += bl.remise;
                vm.total_selected_by_client[client_code].montant_total_ttc += bl.montant_total_ttc;
                vm.total_selected_by_client[client_code].net_a_payer += bl.net_a_payer;
                vm.total_selected_by_client[client_code].total_facture += bl.total_facture;
                vm.total_selected_by_client[client_code].total_non_facture += bl.total_non_facture;
            }

        })

        vm.total_selected_by_client[client_code].remise = vm.total_selected_by_client[client_code].remise.toFixed(3);
        vm.total_selected_by_client[client_code].montant_total_ttc = vm.total_selected_by_client[client_code].montant_total_ttc.toFixed(3);
        vm.total_selected_by_client[client_code].net_a_payer = vm.total_selected_by_client[client_code].net_a_payer.toFixed(3);
        vm.total_selected_by_client[client_code].total_facture = vm.total_selected_by_client[client_code].total_facture.toFixed(3);
        vm.total_selected_by_client[client_code].total_non_facture = vm.total_selected_by_client[client_code].total_non_facture.toFixed(3);

        vm.recalculateAllSelectedBls();
    }
    /**
     * 
     * @param {string} client_code 
     */
    vm.manageCheckByClient = (client_code) => {

        if (vm.checkByClient[client_code]) {
            _.each(vm.clients_bls[client_code], (bl, index) => {
                if (bl.status_facture !== 'Facturé') {
                    bl.checked = true;
                    vm.clients_bls[client_code][index].checked = true;
                    vm.total_selected_bls++;

                }
            });
        } else {
            _.each(vm.clients_bls[client_code], (bl, index) => {
                bl.checked = false;
                vm.clients_bls[client_code][index].checked = false;
                vm.total_selected_bls--;


            });
        }

        console.log('vm.clients_bls[client_code]', vm.clients_bls[client_code])
        //recalculate total client
        vm.recalculateTotalBYClient(client_code);
    }

    vm.total_selected_bls = 0;

    vm.checkBLForTotalSelection = (client_code, index) => {
        if(vm.clients_bls[client_code][index].checked){
            vm.total_selected_bls++;
        }else{
            vm.total_selected_bls--;
        }
    };
    
    vm.changeGroupeBl = (client_code) => {

        var checked = vm.clients_bls[client_code][0].groupe_bls_facture;

        _.each(vm.details_bls[client_code], (bl, index) => {
            vm.clients_bls[client_code][index].groupe_bls_facture = checked;
        })
    }
    /**
     * getLIST OF BLS by clients
     */
    vm.listBls = function () {

        console.log('vm.listBls called')

        WS.getData('bl/findListBls', vm.search)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                // vm.bls = response.data.bls;
                vm.clients_bls = response.data.clients;
                vm.total_selected_by_client = {};

                // _.each(vm.bls, (bl, index) => {
                //     vm.totalremise += parseFloat(Math.abs(bl.remise));
                //     vm.totalnet += parseFloat(Math.abs(bl.net_a_payer));

                //     if (vm.total_selected_by_client[bl.client_code]) {

                //         vm.total_selected_by_client[bl.client_code].remise += bl.remise;
                //         vm.total_selected_by_client[bl.client_code].montant_total_ttc += bl.montant_total_ttc;
                //         vm.total_selected_by_client[bl.client_code].net_a_payer += bl.net_a_payer;
                //         vm.total_selected_by_client[bl.client_code].total_facture += bl.total_facture;
                //         vm.total_selected_by_client[bl.client_code].total_non_facture += bl.total_non_facture;
                //     } else {

                //         vm.total_selected_by_client[bl.client_code] = {};
                //         vm.total_selected_by_client[bl.client_code].remise = bl.remise;
                //         vm.total_selected_by_client[bl.client_code].montant_total_ttc = bl.montant_total_ttc;
                //         vm.total_selected_by_client[bl.client_code].net_a_payer = bl.net_a_payer;
                //         vm.total_selected_by_client[bl.client_code].total_facture = bl.total_facture;
                //         vm.total_selected_by_client[bl.client_code].total_non_facture = bl.total_non_facture;
                //     }

                // });

                vm.tbodys = '';

                // console.log('vm.clients_bls', vm.clients_bls)
                _.each(vm.clients_bls, (bls, client_code) => {
                    vm.tbodys += `<tr style="background: aliceblue;border-top:2px solid #000!important;">
                    <td colspan="5" class="text-center"> 
                        <h4 class="text-center"> 
                        <i class="fa fa-arrow-down" style="color:green;font-size:17px;"></i> 
                        ${client_code} - ${bls[0].client} - ${bls[0].magasin} </h4>
                    </td>
                    <td colspan="2" class="text-center"></td>
                    <td colspan="6" class="text-center" style="color:green;">
                            <b>Regrouper tous les bls dans une facture : </b> 
                            <input type="checkbox" ng-true-value="1" ng-false-value="0" ng-model="vm.clients_bls['${client_code}'][0].groupe_bls_facture"
                            ng-change="vm.changeGroupeBl('${client_code}');"/>
                    </td>
                    <td  class="text-center" style="color:blue;"> <b>Sélectionner tous les bls de ce client :</b>
                    </td>
                    <td class="text-center">
                        <input type="checkbox" ng-true-value="true" ng-false-value="false" ng-model="vm.checkByClient['${client_code}']"
                        ng-change="vm.manageCheckByClient('${client_code}')"/>
                    </td>
                    </tr>

                    <tr style="background: aliceblue;">
                        <td class="text-center">Index</td>
                        <td class="text-center">Code</td>
                        <td class="text-center">Vendeur</td>
                        <td class="text-center">Camion</td>
                        <td class="text-center">Distributeur</td>
                        <td class="text-center">Client</td>
                        <td class="text-center">Magasin</td>
                        <td class="text-center">Remise</td>
                        <td class="text-center">Total TTC</td>
                        <td class="text-center">Net a payer</td>
                        <td class="text-center">Net a payer Facturé</td>
                        <td class="text-center">Net a payer Reste à Facturé</td>
                        <td class="text-center">Status BL</td>
                        <td class="text-center">date</td>
                        <td class="text-center"></td>
                    </tr>

                    <tr style="background: #cce3f7;">
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"><b>Total : <b></td>
                        <td class="text-center"><b>{{ vm.total_selected_by_client['${client_code}'].remise | number: 3 }}</b></td>
                        <td class="text-center"><b>{{ vm.total_selected_by_client['${client_code}'].montant_total_ttc| number: 3}}</b></td>
                        <td class="text-center"><b>{{ vm.total_selected_by_client['${client_code}'].net_a_payer | number: 3}}</b></td>
                        <td class="text-center"><b>{{ vm.total_selected_by_client['${client_code}'].total_facture | number: 3}}</b></td>
                        <td class="text-center"><b>{{ vm.total_selected_by_client['${client_code}'].total_non_facture | number: 3}}</b></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                    </tr>

                    `;
                    _.each(bls, (value, index) => {

                        //init value.checked false by default
                        vm.clients_bls[client_code][index].checked = vm.clients_bls[client_code][index].checked ? vm.clients_bls[client_code][index].checked : false

                        vm.tbodys += `<tr>
                        <td class="text-center">
                            ${index + 1}
                        </td>
                        <td class="text-center"
                            style="position: relative;">
                            <a class="btn-sm btn-rounded"
                                ui-sref="app.bls.details({id:value.code})">${value.code}</a>
                        </td>
                        <td class="text-center">${value.user}</td>
                        <td class="text-center">${value.depot}</td>
                        <td class="text-center">${value.soussociete}</td>
                        <td class="text-center">${value.client}</td>
                        <td class="text-center">${value.magasin}</td>
                        <td class="text-center">${value.remise}</td>
                        <td class="text-center">${value.montant_total_ttc}</td>
                        <td class="text-center">${value.net_a_payer}</td>
                        <td class="text-center">${value.total_facture}</td>
                        <td class="text-center">${value.total_non_facture}</td>
                        <td class="text-center"
                        ng-class="{ 
                            's-mixte' : vm.clients_bls['${client_code}'][${index}].status_facture=='Mixte', 
                            's-facture' : vm.clients_bls['${client_code}'][${index}].status_facture=='Facturé',
                            's-nonfacture' : vm.clients_bls['${client_code}'][${index}].status_facture=='Non facturé',
                        }"><b>${value.status_facture}</b></td>
                        <td class="text-center">${value.date}</td>
                        <td class="text-center">
                            <input type="checkbox"
                                ng-if="vm.clients_bls['${client_code}'][${index}].status_facture!='Facturé'"
                                ng-model="vm.clients_bls['${client_code}'][${index}].checked"
                                ng-true-value="true"
                                ng-false-value="false"
                                ng-change="vm.recalculateTotalBYClient('${client_code}');vm.checkBLForTotalSelection('${client_code}', '${index}');">
                        </td>
                    </tr>`;


                    });

                });

                vm.tbodys = vm.trustHtml(vm.tbodys);

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });

    }

    /**
     * check all bls
     */
    vm.checkAllBlsFunc = () => {
        vm.total_selected_bls = 0;
        if (vm.checkAllBls) {
            _.map(vm.clients_bls, (bls, client_code) => {
                _.map(bls, (bl, index) => {
                    if (bl.status_facture !== 'Facturé') {
                        vm.clients_bls[client_code][index].checked = true;
                        vm.total_selected_bls++;

                    }
                })
                vm.checkByClient[client_code] = true;
                vm.recalculateTotalBYClient(client_code);
            });
        } else {
            _.map(vm.clients_bls, (bls, client_code) => {
                _.map(bls, (bl, index) => {
                    if (bl.status_facture !== 'Facturé') {
                        vm.clients_bls[client_code][index].checked = false;
                    }
                })
                vm.checkByClient[client_code] = false;

                vm.recalculateTotalBYClient(client_code);
            });
        }

        // recalculate All bls
        vm.recalculateAllSelectedBls();
    }


    vm.listOfBls = {};
    vm.showSelectedBls = () => {

        _.each(vm.clients_bls, (bls, client_code) => {
            var bls_selected = []
            _.each(bls, (bl, index) => {
                if (bl.checked) {
                    bls_selected.push(bl)
                }
            }); 

            if(bls_selected.length>0){
                vm.listOfBls[client_code] = bls_selected;
            }
        });

        if (Object.keys(vm.listOfBls).length == 0) {
            swal('Oups!!', 'Veuillez bien selectionner au moins un bl', 'warning');
        } else {

            vm.details_selected_bls = $uibModal.open({
                animation: true,
                templateUrl: 'details_selected_bls',
                size: 'lg',
                backdrop: true,
                windowClass: 'zindex lg-95',
                controller: function ($scope, $translate) {
                    $scope.listOfBls = vm.listOfBls;
                    $scope.current_date = moment().format('YYYY-MM-DD HH:i:s')
                    $scope.findDetailsBls = function () {
                        WS.post('bl/findDetailsBls', {
                            listOfBls: $scope.listOfBls
                        }).then(
                            function (response) {
                                $scope.details_bls = response.data;
                                $scope.client = Object.values(response.data)[0];
                                $scope.$apply()
                            },
                            function () {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant('erreur de chargement des données'),
                                    'warning'
                                );
                            }
                        );
                    }
                    $scope.findDetailsBls();

                    $scope.transfertAllQuantite = () => {
                        _.each($scope.details_bls, (detail, index) => {
                            detail.quantite_saisie = detail.reste_a_facture;
                        })
                    }
                    $scope.clearAllQuantite = () => {
                        _.each($scope.details_bls, (detail, index) => {
                            detail.quantite_saisie = "";
                        })
                    }



                    $scope.closePanel = () => {
                        vm.details_selected_bls.close({});
                    }

                    $scope.submitFacture = (details_bls) => {

                        var error = false;
                        //check quantites
                        _.each($scope.details_bls, (detail, index) => {
                            if (detail.quantite_saisie > detail.reste_a_facture) {
                                error = true;
                            }
                        });

                        if (error) {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Merci de vérifier attentivement les quantités!'),
                                'warning'
                            );
                        } else {

                            const lignes = _.filter($scope.details_bls, detail => Number(detail.quantite_saisie) > 0)
                            swal({
                                title: $translate.instant(`Validation`),
                                // text: $translate.instant('code bl') + ` : `,
                                html: `${$translate.instant('Voulez-vous vraiment AJouter cette facture!')}`,
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: $translate.instant('Oui!'),
                                cancelButtonText: $translate.instant('Non, Annuler!'),
                                confirmButtonClass: 'btn btn-success',
                                cancelButtonClass: 'btn btn-danger',
                                buttonsStyling: false
                            }).then(function () {

                                console.log($scope.details_bls)
                                WS.post('bl/addNewFacture', { facture: lignes, entete: $scope.client }).then(
                                    (response) => {
                                        if (response.data.success) {
                                            swal(
                                                $translate.instant('Succès'),
                                                $translate.instant('Facture Ajoutée'),
                                                'success'
                                            ).then(function () {
                                                // $window.location.reload();
                                            }).catch(function () {
                                                // $window.location.reload();
                                            });;
                                        }
                                        $scope.$apply();
                                    },
                                    (error) => {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant('erreur de traitement des données'),
                                            'warning'
                                        );
                                    }
                                )
                            });
                        }

                    };


                }
            });

        }
    };

}
export default {
    name: 'facturesController',
    fn: facturesController
};